import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const Content = styled.div`
  position: relative;
  margin-top: 10px;
  background: var(--bg-secondary) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  backdrop-filter: blur(29px);
  -webkit-backdrop-filter: blur(29px);
  overflow: hidden;
`

const ContentCard = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.4fr 0.5fr;
  gap: 8px;
  scale: 0.8;

  .oneImg {
    margin-right: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    justify-content: center;
    align-items: center;
    scale: 1;

      &::after {
      content: '';
      background: url(${props => props.bgImgPrimary});
      background-size: contain;
      background-repeat: no-repeat;
      background-attachment: fixed;
      opacity: 1;
      overflow: hidden;
      border-radius: 15px;
      filter: blur(27px);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      scale: 2;
      position: absolute;
    }
  }

  .multiImg {
    overflow: hidden;
  }

  .multiImg .topImg {
    position: relative;
    overflow: hidden;
    border-radius: 15px;

    &::after {
      content: '';
      background: url(${props => props.bgImgSecondary});
      background-size: contain;
      background-repeat: no-repeat;
      background-attachment: fixed;
      opacity: 1;
      filter: blur(27px);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      scale: 2;
      position: absolute;
    }
  }

  .multiImg .belowImg {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 15px;
    margin-top: 10px;

    .belowOne {
      padding: 10px;
      overflow: hidden;
      position: relative;
      margin-right: 10px;
      &::after {
        content: '';
        background: url(${props => props.bgImgThird});
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 1;
        backdrop-filter: blur(20px);
        filter: blur(27px);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        scale: 2;
        position: absolute;
      }
    }

    .belowTwo {
      padding: 10px;
      overflow: hidden;
      position: relative;

      &::after {
        content: '';
        background: url(${props => props.bgImgFour});
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 1;
        backdrop-filter: blur(20px);
        filter: blur(27px);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }

  .wrapper-menu {
    position: relative;
    display: flex;
    align-items: center;
  }

  .list-menu {
    display: flex;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    align-items: center;
    text-align: center;
    scale: 1;
    color: var(--gray);
    transition: 0.5s all;
    width: 210px;

    i {
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 20px;
    }

    button {
      align-items: center;
    }

    &.-active {
      color: var(--font-secondary);
      font-size: 30px;
      scale: 1.5;

      i {
        background-color: var(--font-secondary);
        border-radius: 50%;
      }
    }
  }
`

export default function GameCategoryComponent({ list, headerText, categories }) {
  const [select, setSelect] = useState('All Games');
  const [newData, setNewData] = useState();

  const toggleSelect = (value) => setSelect(value);

  useEffect(() => {
    const rerenderData = async () => {
      if (select === 'All Games') {
        const newList = await list.slice(0, 4)
        setNewData(newList)
      } else {
        let newList = await list.filter(items => items.type === select).slice(0, 4)
        if (newList.length < 4) {
          newList = [...newList, newList[0]]
        }
        setNewData(newList)
      }
    }

    rerenderData();
  }, [list, select])

  const convertFirstLetter = (value) => {
    return (value.text.charAt(0).toUpperCase() + value.text.slice(1))
  }
  return (
    <Wrapper>
      <div className='headText'>{headerText}</div>
      <Content>
        <div className='flex items-center justify-around h-full'>
          <ContentCard
            bgImgPrimary={newData && newData[0]?.image.large}
            bgImgSecondary={newData && newData[1]?.image.large}
            bgImgThird={newData && newData[2]?.image.large}
            bgImgFour={newData && newData[3]?.image.large}
          >
            <div className='oneImg'>
              <img src={newData && newData[0]?.image.large} className='relative z-10' alt='' />
            </div>
            <div className='multiImg'>
              <div className='topImg'>
                <img src={newData && newData[1]?.image.large} className='relative z-10' alt='' />
              </div>
              <div className='belowImg'>
                <div className='belowOne'>
                  <img src={newData && newData[2]?.image.large} className='relative z-10' alt='' />
                </div>
                <div className='belowTwo'>
                  <img src={newData && newData[3]?.image.large} className='relative z-10' alt='' />
                </div>
              </div>
            </div>
            <div className='wrapper-menu'>
              <ul className='absolute right-0'>
                {categories && categories.map((items, index) => (
                  <li className={`list-menu ${select === items.text ? '-active' : ''}`}
                    key={index}>
                    <button onClick={() => toggleSelect(items.text)} className='flex'>
                      <i className={`icon ${items.icon}`}></i>
                      {convertFirstLetter(items)}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </ContentCard>
        </div>
      </Content>
    </Wrapper>
  )
}

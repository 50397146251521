import React from "react"
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ButtonCard from './button';
import hotIcon from "~/images/icon_fire.png"
import newIcon from "~/images/icon_new.png"
import featureIcon from "~/images/icon_feature.png"
import jwt from 'jsonwebtoken';
import {
  DEMO_URL,
} from "gatsby-env-variables"
import PlayUrlContext from "~/context/play-url"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { number } from "prop-types";

const WrapperCarousel = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`

const WrapperCard = styled.div`
  display: block;
  margin-top: 10px;
  background: var(--bg-primary);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  .boxImage {
    position: relative;
  }

  .glassImg {
    visibility: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 20;
    opacity:0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
  }

  .content {
    background: #fff;
    padding: 10px;
  }

  .detailTag {
    position: absolute;
    bottom: 0;
    z-index: 10;
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: transparent linear-gradient(180deg, #ECF4FF00 0%, var(--color-forth) 50%, var(--color-third) 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;

    .logo {
      width: 100%;
      max-width: 70px;
      margin: auto;
    }
  }
`

const BottomTag = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  padding-top: 70%;
  background: transparent linear-gradient(180deg, var(--bg-card-primary) 0%, var(--bg-card-secondary) 180%, var(--bg-card-third) 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  transition: 0.5s all, color 0s;
  overflow: hidden;
  color: var(--font-primary);

  .tagWrapper {
    position: inherit;
    font-size: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-grow: 2;
    padding: 0.5rem;

    div {
      padding: 2px 10px 2px 15px;
      margin: auto;

      & + div {
        margin-top: 5px;
      }
    }
  }
`

const DetailGame = styled.div`
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  transition: opacity 0.5s;
  z-index: 20;

  ${WrapperCard}:hover & {
    visibility: visible;
    opacity:1;
    position: absolute;
    padding-top: 70%;
    border-radius: 8px;
    opacity: 1;
    text-align: center;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: var(--font-primary);

    &.bgDark {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: var(--font-primary);
    }
  }
`

const TagWrapper = styled.div`
  font-size: 10px;
  width: 150px;
  margin: auto;

  div {
    padding: 2px 5px 2px 15px;
    margin: 3px;

    & + div {
      margin-top: 5px;
    }
  }
`

export default function CarouselVerticalComponent({ list, headerText, langKey, cate }) {
  const { isMobile } = React.useContext(PlayUrlContext)

  const gameFilter = [cate];
  
  const game = list.filter(item => gameFilter.includes(item.tag[0]))
  const numberToShow = (game.length / 3) % 1 > 0 ? (game.length / 2) % 1 > 0 ? 1 : 2 : 3
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: numberToShow,
    slidesToScroll: numberToShow,
    variableWidth: true,
    gap: 10,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button onClick={onClick}>
        <div
          className={className}
          style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      </button>
    );
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button onClick={onClick}>
        <div
          className={className}
          style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </button>
    );
  };

  const openGames = (value) => {
    if (!value.status && value.demoUrl) {
      const playStaging = jwt.sign({
        link: value.demoUrl + `?lang=${langKey}`,
        provider: value.partner,
        gameName: value.name,
        mode: !isMobile ? 'desktop' : 'mobile',
        lang: langKey,
        backLink: '',
        gameType: value.type,
        prefix: value.prefix,
      }, 'wrong-secret')
      const Features = 'top=0,left=0,height=768,width=1366,scrollbars=no'
      window.open(`${DEMO_URL}?link=${playStaging}`, Features, 'popup')
    }
  }

  return (
    <WrapperCarousel>
      <div className='headText'>{headerText}</div>
      <Slider {...settings}>
        {list.filter(item => gameFilter.includes(item.tag[0])).map((items, index) => (
          <div className='relative px-1' key={index}>
            <WrapperCard key={index}>
              <img src={items.image.portraitImage} alt='' />
              <BottomTag>
                <div className='detailTag'>
                  <img src={items.image.logoImage} className='logo' alt='logo' />
                  <div className='w-full text-center'>{items.name[langKey]}</div>
                  <TagWrapper className={`bottom-0 cursor-pointer items-center flex-wrap justify-center ${items.tag[0] ? "flex" : "hidden"}`}>
                    {items.tag.map((item, idx) => (
                      <div
                        key={idx}
                        className="table rounded"
                        style={{
                          backgroundColor:
                            item === "NEW"
                              ? "#13C750"
                              : item === "HOT"
                                ? "#FC4C60"
                                : "#7639FF",
                          color: "#fff",
                          backgroundImage:
                            item === "NEW"
                              ? `url(${newIcon})`
                              : item === "HOT"
                                ? `url(${hotIcon})`
                                : `url(${featureIcon})`,
                          backgroundSize: "10px",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "3px center",
                        }}
                      >
                        {item}
                      </div>
                    ))}
                  </TagWrapper>
                </div>
                <DetailGame>
                  {items.name[langKey]}
                  <hr />
                  <div className='flex justify-center'>
                    <button onClick={() => openGames(items)}>
                      <ButtonCard text={'TRY NOW'} />
                    </button>
                  </div>
                </DetailGame>
              </BottomTag>
            </WrapperCard>
          </div>
        ))}
      </Slider>
    </WrapperCarousel>
  )
}
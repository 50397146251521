import React, { useState } from "react"
import styled from 'styled-components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  p {
    color: var(--font-primary);
  }

  /* .headText {
    font-weight: bold;
    font-size: 26px;
    padding-left: 30px;
    color: var(--font-primary);

    &::before {
      position: absolute;
      content: "";
      left: 0px;
      width: 5px;
      height: 40px;
      border-radius: 8px;
      background: linear-gradient(188deg,var(--border-third) 44%,var(--border-four) 100%) no-repeat padding-box;
      opacity: 1;
    }
  } */
`

const FeatureBox = styled.div`
  text-align: center;
  color: var(--font-primary);
  img {
    border-radius: 32x;
    padding: 10px 10px;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .spacer {
    flex-grow: 0.5!important;
  }

  .slider-1 {
    width: 50%;
    padding: 1.25rem 0rem;
    max-width: 300px;

    @media screen and (max-width: 768px) {
      margin: auto;
    }
  }

  .slider-2 {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    display: block;

    .slider-1 {
      width: 100%;
      padding: 1.25rem 0rem;
    }

    .slider-2 {
      padding-top: 20px;
      width: 100%;
    }
  }
`

export default function FeatureComponent({ list, headerText }) {
  const settings1 = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
  };

  const settings2 = {
    className: "center",
    arrows: false,
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
  };

  const settings3 = {
    arrows: false,
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    speed: 500,
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
  };

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [nav3, setNav3] = useState();
  return (
    <Wrapper>
      <div className='headText'>{headerText}</div>
      <Content>
        <div className='slider-1'>
          <Slider
            asNavFor={nav2}
            ref={(slider1) => setNav1(slider1)}
            focusOnSelect={true}
            {...settings1}>
            {list.map((items, index) => (
              <div key={index} className='img-promo'>
                <img src={items.frontmatter.image} alt='' />
              </div>
            ))}
          </Slider>
        </div>
        <div className='spacer'></div>
        <div className='slider-2'>
          <Slider
            asNavFor={nav3}
            ref={(slider2) => setNav2(slider2)}
            focusOnSelect={true}
            {...settings2}
          >
            {list.map((items, index) => (
              <FeatureBox key={index}>
                <img src={`/${items.frontmatter.thumbnail}`} alt="" />
                <div className='pt-5'>
                  {items.frontmatter.title}
                </div>
              </FeatureBox>
            ))}
          </Slider>
          <Slider
            asNavFor={nav1}
            ref={(slider2) => setNav3(slider2)}
            focusOnSelect={true}
            {...settings3}
          >
            {list.map((items, index) => (
              <div
                key={index}
                className='p-5 detail'
                dangerouslySetInnerHTML={{
                  __html: items.html,
                }}
              ></div>
            ))}
          </Slider>
        </div>
      </Content>
    </Wrapper>
  )
}
import React from "react"
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const WrapperCarousel = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  .headText {
    font-weight: bold;
    font-size: 26px;

    &::before {
      content: "";
      width: 4px;
      height: 4px;
      border: 4px #13C750 solid;
      box-shadow: 0px 3px 6px #00000029;
      opacity: 1;
      margin-right: 20px;
    }
  }

  .slick-list > div {
    margin-left:0;
  }
`

const WrapperCard = styled.div`
  display: block;
  margin-top: 10px;
  background: var(--bg-primary);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  padding: 20px;
  text-align: center;

  div, p {
    color: var(--font-primary);
  }

  img-bg {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 16px;
    margin-bottom: 10px;
  }

  .tag-event {
    position: absolute;
    margin-top: 20px;
    left: 0;
    background: #8F39CE;
    border-radius: 8px;
    color: #fff;
    padding: 5px;
  }

  .logo-image {
    position: absolute; 
    left: 0;
    right: 0;
    bottom: 0;
  }

  // @media screen and (max-width: 768px) {
  //   height: 355px;
  // }
`

const ImageCard  = styled.div`
  position: relative;
  width: 100%;
  .image-card {
    border-radius: 24px;
    position: relative;
  }

  .logo-image {
    position: absolute; 
    left: 0;
    bottom: 0;
  }
`

export default function CarouselNewsMobile({ list, langKey, currentMenu }) {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1208,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 5.5,
        }
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3.5,
        }
      },
      {
        breakpoint: 414,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1.5,
        }
      },
    ]
  };

  return (
    <WrapperCarousel>
      <Slider {...settings}>
        {list.map((items, index) => (
          <div className='relative px-1' key={index}>
            <WrapperCard key={index}>
              {!items.games 
                ? 
                <ImageCard>
                  <img className="image-card" src={items} alt=''/>
                </ImageCard>
                :
                <div>
                  <ImageCard>
                    <img className="image-card" src={items.games[0].ambplayImgUrl.card} alt='' />
                    <img className="logo-image" src={items.games[0].ambplayImgUrl.logo} alt='' />
                  </ImageCard>
                  <div className="font-bold">{items.games[0].prefix}</div>
                  <div>{items.date}</div>
                </div>
              }
            </WrapperCard>
          </div>
        ))}
      </Slider>
    </WrapperCarousel>
  )
}
import React, { useState, useEffect } from "react"
import styled from 'styled-components';
import Slider from "react-slick";
import useDarkMode from "use-dark-mode"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const WrapperCarousel = styled.div`
  position: relative;
  width: 100%;
`

const WrapperBoxEvent = styled.div`
  padding-right: 0.25rem;
  width: 100%;
  min-width: 350px ;
  margin: 10px;
  background: var(--bg-secondary) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  overflow: hidden;

  > img {
    width: 170px;
    height: 170px;
    box-shadow: 0px 3px 6px #00000029;
  }

  h1, p {
    color: var(--font-primary);
  }
`

export default function CarouselHorizon({ list, headerText }) {
  const { value } = useDarkMode()
  const [isDarkMode, setisDarkMode] = useState();

  useEffect(() => {
    setisDarkMode(value)
  }, [value])

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1208,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 976,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button onClick={onClick}>
        <div
          className={className}
          style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      </button>
    );
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button onClick={onClick}>
        <div
          className={className}
          style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </button>
    );
  };

  return (
    <WrapperCarousel>
      <div className='headText'>{headerText}</div>
      <Slider {...settings}>
        {list.filter(v => v.tag.includes('HIT')).map((items, index) => (
          <div className='relative px-1' key={index}>
            <WrapperBoxEvent key={index} className='flex' isDarkMode={isDarkMode}>
              <img src={items.image.portraitImage} alt='' />
              <div className='logo'>
                  <img src={items.image.logoImage} alt='' />
                </div>
              <div className='p-5'>
                <h1 className='text-sm font-bold'>
                  {items.prefix}
                </h1>
                <div
                  className="table my-3 rounded"
                  style={{
                    backgroundColor:
                      items.type === "News"
                        ? "#13C750"
                        : items.type === "Event"
                          ? "#FC4C60"
                          : "#7639FF",
                    color: "#fff",
                    padding: "0px 5px",
                    fontSize: "10px",
                    backgroundSize: "8px",
                    borderRadius: "8px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "3px center",
                  }}
                >
                  {items.type}
                </div>
                <div
                  className="detail"
                  dangerouslySetInnerHTML={{
                    __html: items.html,
                  }}
                  
                ></div>
              </div>
            </WrapperBoxEvent>
          </div>
        ))}
      </Slider>
    </WrapperCarousel>
  )
}